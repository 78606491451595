import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import appStyles from '../../App.module.css'
import Shell from '../Shell/Shell'
import styles from './TerminosCondiciones.module.css'

class TerminosCondiciones extends Component {

  componentDidMount(){
    window.scrollTo(0, 0)
  }

  render () {
    return <Shell>
      <div className={appStyles.CenterContainer}>
        <div
          className={`${styles.sectionSpace} ${styles.centerContainer} ${styles.sectionBottom50}`}>
          <div
            className={`${styles.mediumTitle} ${styles.marginTopTitle} ${styles.sectionBottom50}`}>
            Términos y Condiciones
          </div>
          <p>Moons, en lo sucesivo la “Empresa”, con domicilio en Colima 220,
            Col. Roma Norte C.P. 06700 en México, Ciudad de México, expide los
            siguientes Términos y Condiciones para que a través de su diversos
            medios electrónicos se comercialicen y se ofrecen diversos productos
            al público en general, identificados de manera individual como
            “Usuario”.</p>
          <p><b>Página de Internet de la Empresa:</b></p>
          <p>Es el sitio Web de Moons, bajo la denominación www.mymoons.mx en el
            cual Moons publica Ofertas de Venta, en donde se puede registrar el
            usuario para adquirir los Productos publicados, realizar Pedidos,
            ponerse en contacto con Moons o en servicio al Cliente a través del
            correo electrónico <a className="underline-link"
                                  href="mailto:ayuda@mymoons.mx">ayuda@mymoons.mx</a>,
            entre otros.</p>
          <p><b>Declaraciones:</b></p>
          <p>Moons declara que es una Empresa legalmente constituida bajo las
            leyes mexicanas, que tiene todos los permisos necesarios para
            cumplir su Objeto Social y que no tiene algún tipo de impedimento o
            limitante para cumplir con el mencionado Objeto, que incluye, la
            celebración del Contrato.</p>
          <p>Moons concede al Usuario una licencia personal limitada, no
            exclusiva, no transferible, revocable, por plazo indeterminado,
            conforme a los presentes Términos y Condiciones, para utilizar el
            Sitio, a fin de avalar, manifestar o interesarse en adquirir y
            adquirir mediante Oferta de Venta los productos que en la Página se
            encuentren publicados. La forma de utilizar el Sitio es personal e
            intransferible.</p>
          <p>Moons declara ser propietaria de los Bienes o Productos que se
            encuentren en las Ofertas de Venta, además de todos los elementos
            amparados por normas de propiedad intelectual. Todo lo anterior para
            efecto del Contrato.</p>
          <p>Moons declara que el contenido neto del Producto publicado en la
            Oferta de Venta corresponde a la Calidad, marca y demás elementos
            que se señalen en la propia Oferta de Venta.</p>
          <p>Moons se reserva todos los derechos no expresamente otorgados bajo
            el presente documento.</p>
          <p>Este contrato y cualesquiera derechos y licencias otorgadas aquí,
            no podrán ser transferidos o cedidos por el Usuario pero Moons si
            estará en posibilidad de transferirlos o cederlos sin restricción
            alguna.</p>
          <p>El Usuario acepta Términos y Condiciones y se obliga a todo lo
            señalado en los mismos.</p>
          <p>El Usuario está de acuerdo en que las exclusiones de garantía y
            limitaciones de responsabilidad establecidas con anterioridad son
            elementos fundamentales de la base de estos Términos y
            Condiciones.</p>
          <p>El Usuario está consciente de que el tráfico de datos que
            proporciona acceso al Sitio es apoyado por un servicio prestado por
            la operadora de servicios de telecomunicaciones seleccionada y
            contratada por el Usuario y que dicha contratación es totalmente
            independiente del Sitio.</p>
          <p>El Usuario reconoce que las comisiones cobradas por la operadora de
            servicios de telecomunicaciones de su elección y los impuestos
            aplicables pueden afectar el tráfico de datos necesarios para
            eventuales descargas y anuncios de un tercero en el dispositivo.</p>
          <p>El Usuario declara y reconoce que el download de cualquier
            contenido del Sitio no le confiere la propiedad sobre cualesquiera
            marcas exhibidas en el Sitio.</p>
          <p><b>Contrato</b></p>
          <p>Es aquel que la Empresa le transmite la propiedad de un bien mueble
            al Usuario con Registro que acepta una Oferta de Venta, creando así
            un pedido y pagando a cambio un precio cierto en moneda de curso
            legal de los Estados Unidos Mexicanos. Dicho contrato se someterá a
            los presentes Términos y Condiciones.</p>
          <p><b>Ofertas de Venta</b></p>
          <p>
            Es toda aquella publicación de un Producto en la Página de la
            Empresa, que contenga un precio y descripción del Producto que puede
            constar de clasificación, denominación, serie, modelo, color, etc.
            El precio señalado en la Oferta de Venta no es negociable, y se le
            respetará el descuento o quita señalada en la propia Oferta de
            Venta.<br/>
            El Precio excluye impuestos, gastos de envío, seguro e instalación,
            salvo que se señale explícitamente lo contrario.
          </p>
          <p><b>Pedido</b></p>
          <p>Es la solicitud que hace el Usuario para la adquisición de un
            Producto en respuesta de una Oferta de Venta. El contrato se
            perfeccionará cuando el Usuario reciba, mediante cualquier medio la
            Confirmación del Pedido mismo que será emitido por la Empresa. En el
            caso que exista cualquier tipo de error en la Confirmación de
            Pedido, el Usuario deberá notificar a la Empresa, en el correo
            denominado ayuda@mymoons.mx de dicho error mediante una notificación
            por escrito.</p>
          <p><b>Forma de Pago</b></p>
          <p>Una vez que se vaya a efectuar el pago del producto seleccionado,
            el Usuario deberá confirmar el monto a pagar para cubrir el costo
            del mismo; Los pagos pueden realizarse por diferentes medios ya sea
            Tarjeta Bancaria, (crédito o debito), pago en efectivo (será
            aplicable únicamente a las ciudades señalas en la Página de
            Internet), depósito o Transferencia ante cualquier Institución, o
            algún otro medio que la Empresa se reserve a publicar. La Forma de
            Pago se realizará después de Recibir la Confirmación de Pedido.</p>
          <p>El Usuario deberá notificar a la Empresa cualquier cargo indebido o
            fraudulento en la tarjeta o cuenta bancaria utilizada para las
            compras, mediante correo dirigido a <a className="underline-link"
                                                   href="mailto:ayuda@mymoons.mx">ayuda@mymoons.mx</a>,
            en el menor plazo de tiempo posible para que la Empresa realice las
            gestiones oportunas, así como del extravío, pérdida, hurto o robo,
            inmediatamente después de tener conocimiento del hecho.</p>
          <p><b>Método de Envío</b></p>
          <p>El Usuario deberá cubrir los costos de transporte y envío de los
            productos, salvo que se señale explícitamente lo contrario.</p>
          <p>El Envío Gratis es aplicable únicamente en las compras que
            ascienden al monto que la Empresa establezca en la Página de
            Internet.</p>
          <p>Se le informará al Usuario previamente el precio, fecha aproximada
            de entrega, costos de seguro y flete, este se erogará al momento de
            confirmar el pedido.</p>
          <p><b>Entrega del Producto</b></p>
          <p>La entrega se realizará en el domicilio señalado por el Usuario en
            el Portal de la Cuenta o en una dirección diferente proporcionada
            por el Usuario, que deberá realizarse al momento de confirmar el
            Pedido.</p>
          <p>Las entregas pueden efectuarse por partes, dentro del plazo
            señalado al momento de la Confirmación del Pedido, dicho plazo
            empezará a correr una vez que sea aprobado el pago; el usuario podrá
            revisar el status de entrega de su producto en el portal.</p>
          <p>En caso que el Producto no sea entregado en el plazo fijado, no se
            le podrá reclamar ningún tipo de interés a la Empresa, el Usuario
            podrá contactar al Centro de Atención a Clientes de la Empresa,
            donde se expedirá un documento que señale el Estado de Envío y en el
            cual se especifique las razones del retraso.</p>
          <p><b>Recepción del Producto y Devolución</b></p>
          <p>Al momento de Recibir el Producto, en el domicilio señalado, el
            Usuario está obligado a revisar el Producto. En caso de algún
            desperfecto el Usuario podrá ejecutar los medios señalados en el
            apartado de Devolución o Garantías de los presentes Términos y
            Condiciones.</p>
          <p>La Devolución del Producto procederá cuando el producto no exceda
            más de treinta días desde la Entrega del Producto, así como cuando
            la devolución derive de situaciones convencionales, incluso es
            válido en caso que el Producto no sea de las medidas, el color,
            material, etc., que el Usuario deseaba.</p>
          <p>El Usuario deberá devolver el Producto en sus condiciones
            originales, con su empaquetado, acuse de recibido del Producto,
            número de autorización de devolución (o guía) y factura de compra.
            La Empresa reemplazará el bien en un plazo razonable, en el
            entendido de la sujeción a factores independientes de la Empresa
            como son entrega por parte de la empresa de paquetería,
            disponibilidad del producto a sustituir o disponibilidad de las
            piezas del mismo. La Empresa se reserva el derecho a entregar un
            producto distinto, del mismo valor y que cumpla las mismas funciones
            que el solicitado, a causa de la imposible reparación del mismo.</p>
          <p>Existen ciertos productos que por su naturaleza no son aplicables a
            la devolución, señalados de manera expresa en la descripción del
            producto.</p>
          <p><b>Promociones y Descuentos</b></p>
          <p>Son aquellas quitas sobre el precio del producto que se establezcan
            en la Página de Internet, las cuales estarán sujetas a las
            condiciones, restricciones, el plazo de duración o volumen de los
            productos que se encontraran en los diferentes anuncios publicados
            en la Página de Internet.</p>

          <p><b>Restricciones:</b></p>
          <p>Cualquier trasgresión a los presentes Términos y Condiciones por
            parte del Usuario generarán el derecho en favor de Moons de, en
            cualquier momento y sin necesidad de notificación previa de ningún
            tipo, suspender o terminar la prestación de los servicios y/o de
            retirarle o denegarle el acceso al Sitio al Usuario trasgresor, así
            como quitarle o sacarlo del Registro.</p>
          <p>El Sitio puede ser utilizado únicamente con fines lícitos.</p>
          <p>Se encuentra terminantemente prohibido cualquier tipo de copia,
            distribución, transmisión, retransmisión, publicación, impresión,
            difusión y/o explotación comercial del material y/o contenido puesto
            a disposición del público a través de este Sitio, sin el previo
            consentimiento expreso y por escrito de Moons o, en su caso, del
            titular de los derechos de propiedad correspondientes. El
            incumplimiento de lo mencionado sujetará al infractor a todos los
            reclamos civiles y sanciones penales que pudieran corresponder.</p>
          <p>LOS PADRES O TUTORES DE MENORES DE EDAD SERÁN RESPONSABLES POR LOS
            ACTOS POR ELLOS REALIZADOS SEGÚN LO DISPUESTO POR ESTOS TÉRMINOS Y
            CONDICIONES, INCLUYENDO LOS DAÑOS CAUSADOS A TERCEROS, ACCIONES
            REALIZADAS POR ELLOS Y QUE ESTÉN PROHIBIDAS POR LEY Y POR LAS
            DISPOSICIONES DE ESTE ACUERDO, SIN PERJUICIO DE LA RESPONSABILIDAD
            DEL USUARIO, SIEMPRE QUE ÉSTE NO FUESE PADRE O REPRESENTANTE LEGAL
            DEL MENOR INFRACTOR.</p>
          <p>Al momento del Registro de Usuario no se aceptarán y podrán ser
            cancelados en cualquier momento, direcciones de correo electrónico
            (e-mails) o cualquier dato que contengan expresiones o conjuntos
            gráficos-denominativos que ya hayan sido escogidos anteriormente por
            otro Usuario o, que de alguna otra forma, fuesen injuriosos,
            altisonantes, coincidentes con marcas, nombres comerciales, anuncios
            de establecimientos, razones sociales de empresas, expresiones
            publicitarias, nombres y seudónimos de personas de relevancia
            pública, famosos o registrados por terceros, cuyo uso no esté
            autorizado o que fuese en general, contrario a la ley o a las
            exigencias morales y de buenas costumbres generalmente aceptadas,
            así como expresiones que pudieran inducir a otras personas por
            error, quedando claro que el Usuario responderá por el uso indebido
            tanto en el ámbito civil como penal, si aplicase.</p>
          <p>El Usuario no deberá hacer Upload, publicar o de cualquier otra
            forma disponer en el Sitio de cualquier material protegido por
            derechos de autor, registro de marcas o cualquier otro derecho de
            propiedad intelectual sin previa y expresa autorización del titular
            del mencionado derecho.</p>
          <p><b>Terminación del Contrato</b></p>
          <p>El Contrato que se perfecciona al contestar una oferta de venta
            termina en el momento que las partes cumplen con sus
            obligaciones.</p>
          <p>La Empresa podrá terminar anticipadamente el Contrato si:</p>
          <ul>
            <li>El Precio no es pagado puntualmente, a cuyo efecto las partes
              pactan expresamente que la falta de pago total o parcial producirá
              la rescisión de pleno derecho.
            </li>
            <li>El Usuario incumple con cualquiera de las obligaciones asumidas
              en este documento.
            </li>
            <li>Así lo convengan sus intereses cumpliendo previamente con sus
              obligaciones o indemnizarlo por dejar de hacerlo.
            </li>
          </ul>
          <p>La Empresa podrá optar por exigir la ejecución de su obligación de
            pagar el precio o rescindir el Contrato y la indemnización, además
            podrá exigir resarcimiento de daños y perjuicios.</p>
          <p><b>Vigencia de los Términos y Condiciones</b></p>
          <p>La Empresa, así como el Usuario, reconocen que los Términos y
            Condiciones son de vigencia ilimitada.</p>
          <p>Moons se reserva el derecho de efectuar alteraciones al presente
            documento sin necesidad de previo aviso. Por lo anterior Moons
            recomienda al Usuario que vuelva a leer con regularidad este
            documento, de forma que se mantenga siempre informado sobre
            eventuales modificaciones. Las alteraciones al Contrato se volverán
            efectivas inmediatamente después de su publicación en el Sitio.</p>
          <p>Una vez realizadas las modificaciones, se presumirá que el Usuario
            que continúe usando el Sitio tendrá pleno conocimiento, habrá leído
            y consentido los Términos y Condiciones reformados.</p>
          <p>Moons podrá rescindir unilateralmente el presente instrumento,
            mediante previa notificación al Usuario, en el plazo de 1 (un) día,
            la notificación puede ser, a elección de la Empresa, en el Sitio o
            por escrito.</p>
          <p>Si Moons rescinde los Términos y Condiciones o cierra la Página,
            será con previo análisis del Estado de los Contratos, por tal Moons
            no estará obligada a indemnizar al Usuario.</p>
          <p><b>Jurisdicción:</b></p>
          <p>En caso que exista conflicto en relación al Contrato, Moons está en
            la total disposición de llegar a un acuerdo con el Usuario para la
            resolución del Conflicto. Si no existe dicho acuerdo, y en los demás
            casos no relacionados con el Contrato, se llevara la instancia
            correspondiente ante la Procuraduría del Consumidor o bien ante los
            Tribunales de la Ciudad de México.</p>
          <p><b>Interpretación:</b></p>
          <p>Para todo lo relativo a la interpretación de los presentes términos
            y condiciones se estará a lo dispuesto a los mismos, al glosario que
            como usuario puedes consultar, al aviso de privacidad, así como a
            todas las disposiciones que contengan los presentes términos y
            condiciones y a nuestra legislación mexicana vigente en el Distrito
            Federal.</p>
          <p><b>Confidencialidad de la Información:</b></p>
          <p>Los datos de los Usuarios serán administrados únicamente por la
            Empresa en las formas establecidas en este Aviso de Privacidad. La
            Empresa hará todo lo que esté a su alcance para proteger la
            privacidad de la información. Puede suceder que en virtud de órdenes
            judiciales, o de regulaciones legales, la Empresa se vea compelida a
            revelar información a las autoridades o terceras partes bajo ciertas
            circunstancias, o bien en casos que terceras partes puedan
            interceptar o acceder a cierta información o transmisiones de datos
            en cuyo caso la Empresa no responderá por la información que sea
            revelada. En estos casos, la Empresa notificará al Usuario sobre
            ésta situación.</p>
          <p>No asumimos ninguna obligación de mantener confidencial cualquier
            otra información que el Usuario proporcione a través de boletines y
            pláticas en línea (chats), así como la información que obtenga a
            través de los cookies, información que no haya sido directamente
            entregada a la Empresa.</p>
          <p>Se debe aclarar que NO se vende, regala, facilita ni alquila la
            información del Usuario a ningún tercero ni somos dueños de la
            misma.</p>
          <p>La Empresa tomará todas las medidas posibles para mantener la
            confidencialidad y la seguridad descritas anteriormente, pero no
            responderá por los daños o perjuicios que pudieran derivarse de la
            violación de esas medidas por parte de terceros que utilicen las
            redes públicas o el Internet, alterando los sistemas de seguridad
            para obtener acceso a la información de los Usuarios.</p>
          <p>Las vulneraciones de seguridad ocurridas en cualquier fase del
            tratamiento que afecten de forma significativa los derechos
            patrimoniales o morales de los Titulares, serán informadas de forma
            inmediata por la Empresa al Usuario, a fin que este último pueda
            tomar las medidas correspondientes a la defensa de sus derechos.</p>
          <p>Para mayor información, sírvase de consultar el Aviso de Privacidad
            que se encuentra en la Página Web de la Empresa.</p>
          <p><b>Acceso y uso del Sitio:</b></p>
          <p>Tanto el Sitio como cualesquiera otros Sitios de cualquier otra
            forma disponibles en el Sitio (en lo sucesivo los “Micrositios”) son
            propiedad privada y cualesquiera interacciones deben realizarse de
            acuerdo con el presente documento. Sin perjuicio de las demás
            obligaciones del Usuario establecidas según se establece en éstos
            Términos y Condiciones, el Usuario se obliga a (i) no utilizar los
            contenidos y productos del Sitio con la finalidad de faltar a la
            ley, la moral, las buenas costumbres, las reglas y derechos de
            propiedad intelectual, la dignidad, la vida privada, la imagen, la
            intimidad personal y familiar; (ii) observar los más elevados
            patrones éticos y morales vigentes en Internet y las leyes
            nacionales e internacionales aplicables: (iii) no utilizar el
            Registro de Usuario, contenidos y productos proporcionados conforme
            a éstos Términos y Condiciones para transmitir o divulgar material
            ilegal, difamatorio, que viole, o sea abusivo, amenazador, obsceno,
            perjudicial, vulgar, injurioso o de cualquier otra forma censurable;
            (iv) no enviar mensajes que no hubiesen sido solicitados,
            reconocidos como spam, junk mail o cadenas de correspondencia
            (chainletters); no utilizar los Servicios tipo contenidos y los
            productos proporcionados conforme éstos Términos y Condiciones para
            enviar/divulgar cualquier tipo de virus o archivos que contengan
            cualquier de virus o que pudiesen causar daños a su destinatario o a
            terceros; (v) cumplir con todas las leyes aplicables con relación a
            la transmisión de datos desde México o del territorio en que el
            Usuario resida; (vi) no obtener o intentar obtener acceso a otros
            sistemas de redes o de computadoras conectados a los contenidos y
            productos del Sitio; (vii) responsabilizarse íntegramente del
            contenido de los correos electrónicos que se van a retransmitir así
            como del contenido e información que se pusieran a disposición en
            las Promociones del Sitio; (viii) no interferir o interrumpir los
            Servicios o los servidores o redes conectados a los Servicios,
            contenidos y productos abastecidos por medio del Sitio o de los
            Micrositios; y (ix) cumplir con los procedimientos, normas y
            reglamentos aplicables a los contenidos y productos del Sitio,
            divulgados en las páginas y enlaces de cada contenido de los
            Micrositios.</p>
          <p>El Usuario no deberá enviar o transmitir por medio del Sitio
            cualquier contenido o información que contenga cualquier propaganda
            o propuesta relacionada con cualesquiera productos y/o servicios. El
            Usuario no podrá divulgar o hacer cualquier oferta comercial,
            religiosa, política o cualquier otra oferta incluso sin fines
            comerciales, incluyendo pero sin limitar las ofertas a los Usuarios
            para que se conviertan en Usuarios de otros servicios que de
            cualquier manera pudiesen competir con los Servicios prestados por
            la Empresa.</p>
          <p><b>Políticas de Facturación</b></p>
          <ol>
            <li><p>Para solicitar su facturación deberá de hacerlo dentro de un
              plazo no mayor a 5 días hábiles a partir del momento en que recibe
              el correo electrónico que indica que su mercancía fue embarcada.
              En el caso de compras vía COD tendrá el mismo plazo a partir de
              que recibimos su pago.</p></li>
            <li><p>Deberá enviar una solicitud de facturación al correo de <a
              className="underline-link"
              href="mailto:ayuda@mymoons.mx">ayuda@mymoons.mx</a> dentro del mes
              en curso en que se realizó la compra. No procederán solicitudes de
              factura para compras de meses anteriores.</p></li>
            <li><p>Al solicitar su factura será necesario que tenga a la mano y
              proporcione los siguientes requisitos Fiscales:</p></li>
            <ul>
              <li><p>RFC con Homoclave</p></li>
              <li><p>Nombre completo o razón social</p></li>
              <li><p>Domicilio Fiscal completo incluyendo calle, colonia,
                delegación o municipio, estado y código postal</p></li>
              <li><p>Forma en la que realizó el Pago ya se efectivo,
                transferencias electrónicas de fondos, cheques nominativos o
                tarjetas de débito, de crédito, de servicio o las denominadas
                monederos electrónicos que autorice el Servicio de
                Administración Tributaria</p></li>
            </ul>
            <li><p>Deberá proporcionar su e-mail donde le llegará su factura, en
              un plazo no mayor a 5 días hábiles.</p></li>
          </ol>
          <p>Cualquier conducta del Usuario que, a criterio exclusivo de la
            Empresa, pudiera restringir o inhibir el uso del Sitio o de los
            Micrositios por otros Usuarios, terceros, o Moons queda expresamente
            prohibida.</p>
        </div>
      </div>
    </Shell>
  }
}

export default withRouter(TerminosCondiciones)
