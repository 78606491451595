import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
  Redirect
} from "react-router-dom";

import TerminosCondiciones from "./Components/TerminosCondiciones/TerminosCondiciones";
import Privacy from "./Components/Privacy/Privacy";

function App() {

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/terminos-y-condiciones"
          component={TerminosCondiciones}
        ></Route>
        <Route exact path="/aviso-de-privacidad" component={Privacy} />
        <Route path="/" render={() => <Redirect to="/terminos-y-condiciones" />} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
