import React from 'react'
import styles from './Privacy.module.css'
import appStyles from '../../App.module.css'
import Shell from '../Shell/Shell'

const Privacy = () => {
  
  window.scrollTo(0, 0)
  
  return (
    <Shell>
      <div className={appStyles.CenterContainer}>
        <div
          className={`${styles.sectionSpace} ${styles.centerContainer} ${styles.sectionBottom50}`}>
          <div
            className={`${styles.mediumTitle} ${styles.marginTopTitle} ${styles.sectionBottom50}`}>
            Aviso de Privacidad
          </div>
          <p>Grupo Dental Tecnológico Mexicano SAPI de CV (en adelante "Moons" o
            "nosotros"), con domicilio en Ciudad de México, es la entidad
            responsable del tratamiento de sus datos personales, mismos que se
            recaban a través del sitio de internet mymoons.mx (el "Sitio de
            Internet"). En Moons entendemos la relevancia de proteger la
            privacidad de los usuarios con los que interactuamos día a día (los
            "titulares de datos", los "sujetos", los "usuarios" o "usted"). El
            presente Aviso de Privacidad contiene información importante para
            los usuarios del Sitio de Internet de Moons y explica la forma en
            que obtenemos, utilizamos y compartimos sus datos personales y los
            derechos con los que usted cuenta. En Moons nos comprometemos a
            tratar sus datos personales conforme a los principios de licitud,
            calidad, consentimiento, información, finalidad, lealtad,
            proporcionalidad y responsabilidad establecidos en la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares (la
            "Ley").</p>
          <h3>DATOS PERSONALES QUE SE RECABAN</h3>
          <p>Los datos personales que podrá recabar y tratar Moons son:</p>
          <ol>
            <li>Datos de identificación: Información que permite diferenciarlo
              de otros individuos, en específico: nombre, edad y género.
            </li>
            <li>Datos de contacto: Información que nos permite mantenernos en
              contacto con usted, en específico: correo electrónico y teléfono.
            </li>
            <li>Registros electrónicos: Esta información incluye ciertos datos
              que son obtenidos cuando usted accede a nuestra Sitio de Internet
              e incluyen: datos que son obtenidos a través de cookies en nuestro
              Sitio Web, tales como dirección IP con la que accede al Sitio web,
              preferencias de usuario, tipo de navegador, actividad en el sitio,
              y páginas anteriormente visitadas. Moons no recaba datos
              considerados como sensibles por la Ley.
            </li>
          </ol>
          <h3>REQUISITO DE EDAD</h3>
          <p>Usted debe tener al menos 18 años de edad para convenir y aceptar
            estos Términos de Servicio en nombre propio. Si es menor de 18 años,
            su padre, madre o tutor legal debe aceptar estos Términos de
            Servicio y registrarse para el Servicio en nombre de usted. De
            conformidad con la Ley de Protección de Menores en Internet (COPPA,
            por sus siglas en inglés), y según las modificaciones que se
            realicen en forma ocasional, si es menor de 13 años, se le solicita
            que no proporcione información alguna a la Compañía, ya sea mediante
            el uso del Servicio, la participación en alguna de las promociones,
            concursos o sorteos relacionados con el Servicio, o a través de
            cualquier otra actividad.</p>
          <h3>MEDIOS A TRAVÉS DE LOS CUALES OBTENEMOS SUS DATOS PERSONALES</h3>
          <p>Obtenemos sus datos de manera directa, cuando usted nos proporciona
            directamente dichos datos a través del Sitio de Internet. Esto
            sucede cuando usted completa nuestro formulario/cuestionario.</p>
          <h3>USO DE SUS DATOS PERSONALES</h3>
          <p>Sus datos personales serán utilizados para hacerle llegar
            información de interés sobre nuestros productos y servicios, así
            como mantenernos en contacto con usted.</p>
          <h3>OBTENCIÓN DE DATOS A TRAVÉS DE COOKIES Y OTRA TECNOLOGÍAS</h3>
          <p>Le informamos que nuestro Sitio de Internet utiliza cookies y/o
            tecnologías similares. Una cookie es un archivo de datos que se
            almacena en el disco duro de su equipo de cómputo o del dispositivo
            de comunicaciones electrónicas que usted usa al navegar en nuestro
            sitio web, el cual permite intercambiar información de estado entre
            nuestro sitio web y su navegador. La información de estado puede
            revelar medios de identificación de sesión, autenticación o
            preferencias del usuario, así como cualquier dato almacenado por el
            navegador respecto al Sitio de Internet. Los datos obtenidos por
            este medio nos permiten ofrecerle un mejor servicio a través de
            nuestro Sitio Web. La mayoría de navegadores web permiten gestionar
            sus preferencias de cookies. Para bloquear las cookies, usted puede
            ajustar su navegador para que rechace cookies o elimine determinadas
            cookies. Por lo general también debería ser capaz de gestionar
            tecnologías similares del mismo modo que gestiona las cookies -
            utilizando sus preferencias del navegador. Moons trabaja con Google
            Analytics y otros proveedores de servicios que utilizan tecnología
            para ayudarnos a ofrecer nuestros propios contenidos, ofrecer
            publicidad dirigida y obtener métricas anónimas o disociadas y
            análisis del sitio. En los contratos correspondientes, solicitamos a
            estas empresas utilizar la información que obtienen sólo para
            proporcionarnos los servicios que requerimos. Aunque estas empresas
            utilizan alguna información anónima sobre usted para mostrar
            anuncios, prohibimos contractualmente a estas empresas obtener datos
            personales acerca de usted.</p>
          <h3>TRANSFERENCIA DE DATOS PERSONALES Y REMISIÓN DE LA INFORMACIÓN
            OBTENIDA</h3>
          <p>Moons le informa que sus Datos Personales podrán ser compartidos
            dentro o fuera del país con las siguientes personas, empresas,
            organizaciones o autoridades:
            <ul>
              <li>Transferencias de datos personales a terceros. Sus datos
                personales podrán ser comunicados o transferidos a un tercero
                cuando contemos con su consentimiento o cuando éste no sea
                necesario para la transferencia en términos del artículo 37 de
                la Ley. Usted acepta específicamente que sus datos sean
                transferidos a terceros en los siguientes casos:
                <ol>
                  <li>cuando Moons deba transferir los datos a las autoridades
                    competentes, encargadas de la procuración o administración
                    de justicia, cuando por mandato judicial así nos lo
                    requieran;
                  </li>
                  <li>cuando los datos requieran ser transferidos a un tercero
                    en el contexto de una transacción corporativa (incluyendo
                    fusiones, adquisiciones, entre otras).
                  </li>
                </ol>
              </li>
            </ul>
          </p>
          <h3>EJERCICIO DE DERECHOS ARCO; REVOCACIÓN DE CONSENTIMIENTO</h3>
          <p>Usted goza, en todo momento, del derecho para solicitar a Moons el
            acceso a sus datos personales, a la rectificación de los mismos
            cuando sean inexactos o se encuentren desactualizados; asimismo,
            usted podrá solicitar que sus datos sean cancelados, cuando hayan
            dejado de ser necesarios para las finalidad para los cuales los
            proporcionó, oponerse a que Moons trate dichos datos para fines
            específicos ("Derechos ARCO") y/o revocar el consentimiento que
            previamente nos haya otorgado para tratarlos. Por favor considere
            que sus Derechos ARCO están sujetos a las limitaciones que
            establecen las leyes aplicables y que es posible que, en algunos
            casos, su solicitud no resulte procedente. Para ejercitar dichos
            derechos o para formular cualquier duda o queja con relación al
            tratamiento de sus datos personales por favor contacte a nuestro
            Departamento de Datos Personales ("DDP"). Por favor dirija su
            solicitud a: ayuda@mymoons.mx, en atención al "Responsable del
            Departamento de Datos Personales". Sus solicitudes serán evaluadas
            en los términos establecidos en la Ley. Es necesario que para las
            solicitudes de acceso, rectificación, cancelación y oposición nos
            haga llegar la siguiente información:
            <ol>
              <li>nombre del titular y domicilio u otro medio para comunicarle
                la respuesta a su solicitud;
              </li>
              <li>los documentos que acrediten la identidad o, en su caso, la
                representación legal del titular;
              </li>
              <li>la descripción clara y precisa de los datos personales
                respecto de los que se busca ejercer alguno de los derechos, y
              </li>
              <li>cualquier otro elemento o documento que facilite la
                localización de los datos personales. La obligación de acceso a
                la información se dará por cumplida cuando se pongan a
                disposición del titular los datos personales o mediante la
                expedición de copias simples o documentos electrónicos, según
                resulte más sencillo.
              </li>
            </ol>
          </p>
          <h3>OPCIONES Y MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS
            PERSONALES</h3>
          <p>En general, cuando recabemos datos personales de usted con respecto
            a una finalidad que consideremos no es necesaria o esencial en el
            contexto de nuestra relación jurídica con usted, pondremos a su
            disposición un mecanismo que le permita manifestar su negativa para
            que tratemos sus datos para ese fin. Este mecanismo estará
            disponible antes de recabar sus datos; asimismo, estos mecanismos
            estarán disponibles en todo momento, y para poder manifestar su
            negativa, bastará que usted formule su solicitud en términos de la
            cláusula anterior. Si usted desea saber acerca de otras opciones que
            pudieran estar disponibles para que usted pueda limitar el uso o
            divulgación que hacemos de sus datos personales, por favor envíenos
            un correo electrónico a ayuda@mymoons.mx</p>
          <h3>MEDIDAS PARA PROTEGER LOS DATOS PERSONALES</h3>
          <p>Moons ha implementado y mantiene medidas de seguridad
            administrativas, físicas, y técnicas para proteger sus datos
            personales contra daño, pérdida, alteración, destrucción o el uso,
            accesos o tratamiento no autorizados.</p>
          <h3>MODIFICACIONES A ESTE AVISO DE PRIVACIDAD</h3>
          <p>Moons se reserva el derecho de modificar o actualizar este Aviso de
            Privacidad en cualquier momento, en cuyo caso, se publicará una nota
            visible en el Sitio de Internet, o se le hará llegar la versión
            modificada a través de correo electrónico o de algún otro medio de
            comunicación que tengamos con Usted. En la publicación se hará notar
            la fecha de la última actualización. Si usted no está de acuerdo con
            las modificaciones realizadas al Aviso de Privacidad, por favor
            contáctenos al correo ayuda@mymoons.mx y absténgase de hacer uso de
            nuestro Sitio de Internet. El uso continuo de nuestro Sitio de
            Internet representa su consentimiento y acuerdo con el nuevo Aviso
            de Privacidad. Moons lo invita a revisar periódicamente nuestro
            Aviso de Privacidad.</p>
        </div>
      </div>
    </Shell>
  )
}

export default Privacy
